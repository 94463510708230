const routes = require(`./routes.js`);

const { PLATFORMS } = require('@oup/shared-node-browser/constants');

const baseUrls = {
  ces: process.env.CES_BASE_URL,
  olb: process.env.OLB_BASE_URL,
  ote: process.env.OTE_BASE_URL,
  opt: process.env.OPT_BASE_URL,
  oic: process.env.OIC_BASE_URL,
  owl: process.env.OWL_BASE_URL,
  rvs: process.env.RVS_BASE_URL,
  ocp: process.env.OCP_BASE_URL,
  orb_unity: process.env.ORB_UNITY_BASE_URL,
  orb_unity_support: process.env.ORB_SUPPORT_URL,
  orb_eps: process.env.ORB_EPS_BASE_URL,
  oald: process.env.OALD_BASE_URL,
  elt: process.env.ELT_BASE_URL,
  auth0: process.env.OIDC_IDP_URL,
  hub: process.env.HUB_BASE_URL,
  ltihub: process.env.LTI_HUB_BASE_URL,
  kerboodle: process.env.KERBOODLE_BASE_URL,
  smart: process.env.SMART_BASE_URL
};

const urls = {};

// add the relevant base URL to all routes
Object.keys(routes).forEach(project => {
  if (!baseUrls[project]) {
    // throw new Error(`Please define a baseUrl for ${project}`);
    baseUrls[project] = '/';
  }

  Object.keys(routes[project]).forEach(route => {
    urls[route] = baseUrls[project] + routes[project][route];
  });
});

// return platform specific baseurls.
const getPlatformUrl = (platform, support = false) => {
  if (platform === PLATFORMS.OLB || platform === PLATFORMS.OLB_MOBILE) {
    return baseUrls.olb;
  }
  if (platform === PLATFORMS.ORB || platform === PLATFORMS.ORB_MOBILE) {
    return support ? baseUrls.orb_unity_support : baseUrls.orb_unity;
  }
  if (platform === PLATFORMS.HUB) {
    return baseUrls.hub;
  }
  if (platform === PLATFORMS.CES) {
    return baseUrls.ces;
  }
  if (platform === PLATFORMS.SMART) {
    return baseUrls.smart;
  }
  if (platform === PLATFORMS.OTE) {
    return baseUrls.ote;
  }
  if (platform === PLATFORMS.OPT) {
    return baseUrls.opt;
  }
  if (platform === PLATFORMS.OIC) {
    return baseUrls.oic;
  }
  if (platform === PLATFORMS.RVS) {
    return baseUrls.rvs;
  }
  if (platform === PLATFORMS.OCP) {
    return baseUrls.ocp;
  }
  if (platform === PLATFORMS.OWL) {
    return baseUrls.owl;
  }

  return baseUrls.ces;
};

// return orb_eps (oxfordreadingbuddy.oup.com) for ORB platform specific to signin card download
const getPlatformUrlOrbEps = () => baseUrls.orb_eps;

/* return platform urls in common pattern
 *  after using platform filter.
 */
const filterPlatformUrls = platform => {
  const result = {};

  if (!routes[platform]) {
    return result;
  }

  Object.keys(routes[platform]).forEach(route => {
    const newRoute = route.replace(`${platform.toUpperCase()}_`, '');
    result[newRoute] = baseUrls[platform] + routes[platform][route];
  });
  return result;
};
module.exports = { urls, getPlatformUrl, filterPlatformUrls, getPlatformUrlOrbEps };
