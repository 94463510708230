/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { GlyphIcon, EmblemSize, EmblemShape } from '../IconEmblem/IconEmblem';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './ContentPlayerMenu.scss';
import {
  MENU_ORIENTATION,
  selfSelectedUserRoles,
  MODAL_CLASSES,
  FEATURES,
  STAGED_DEFAULT_ASSETS_DOMAIN
} from '../../globals/cptConstants';
import {
  setCurrentContent,
  setMenuOrientation,
  toggleContentsSubmenuModal,
  toggleResourcesSubmenuModal,
  togglePrintViewModal,
  toggleDictionaryModal,
  toggleSwitchBook,
  openContentsSubmenuModal
} from '../../redux/actions/structuredContentPlayer';
import ContentPlayerSidebar from '../ContentPlayerSidebar/ContentPlayerSidebar';
import ResourcesPlayerSidebar from '../ResourcesPlayerSidebar/ResourcesPlayerSidebar';
import ContentPlayerPrintView from '../ContentPlayerPrintView/ContentPlayerPrintView';
import ContentPlayerDictionary from '../ContentPlayerDictionary/ContentPlayerDictionary';
import ContentPlayerSwitchBook from '../ContentPlayerSwitchBook/ContentPlayerSwitchBook';
import ContentPlayerResourceModal from '../ContentPlayerResourceModal/ContentPlayerResourceModal.js';
import PopoutPanel from '../PopoutPanel/PopoutPanel';
import withFocusTrap from '../../decorators/withFocusTrap';
import { onKeyDownHandler } from '../../services/cptHelperMethods';
import { isLocal } from '../../globals/envSettings.js';
import { EPS_ASSETS_BASE_ELT_MISC } from '../../../sharedNodeBrowser/constants';
import { isOcpMode } from '../../utils/platform.js';

const CONTENT_PATH = isLocal()
  ? `${STAGED_DEFAULT_ASSETS_DOMAIN}${EPS_ASSETS_BASE_ELT_MISC}`
  : `https://${window.location.hostname}${EPS_ASSETS_BASE_ELT_MISC}`;

// TODO: over 7 parameters
function ContentPlayerMenu({
  onRender,
  menuOrientation,
  closePanel,
  setMenuOrientationAction,
  productVariant,
  dictionaryEnabled,
  dictionarySearchOrder,
  isContentPlayerSidebarOpen,
  isResourcesSidebarOpen,
  isPrintViewModalOpen,
  isDictionaryModalOpen,
  toggleContentsSidebarModal,
  openContentsSubmenuModalAction,
  toggleResourcesSidebarModal,
  togglePrintViewModalContent,
  toggleDictionaryModalContent,
  currentActivity,
  printView,
  cptContent,
  resources,
  features,
  parentRef,
  toggleSwitchBookAction,
  isSwitchBookOpen,
  isResourceModalPlayerOpen
}) {
  const [printViewPages, setPrintViewPages] = useState([]);
  const [printViewSpreadImage, setPrintViewSpreadImage] = useState('');

  const { resources: resourcesFeature, printView: featuresPrintView, switchBook } = features;

  const isTeacher = productVariant === selfSelectedUserRoles.SELF_TEACHER;
  const shouldDisplayResourcesFeature = resourcesFeature !== FEATURES.HIDE_RESOURCES;
  let shouldDisplayPrintView = featuresPrintView !== FEATURES.HIDE_PRINT_VIEW;
  if (isOcpMode) {
    shouldDisplayPrintView = false;
  }
  const shouldDisplaySwitchBook = isTeacher && switchBook !== FEATURES.HIDE_SWITCH_BOOK;
  const hasLinkedContentAndSwitchBook = currentActivity?.linkedContentCodes?.length > 0 && shouldDisplaySwitchBook;
  const isTeacherWithLinkedContentAndSwitchBook = hasLinkedContentAndSwitchBook && isTeacher;
  const isStudentWithLinkedContentAndSwitchBook = hasLinkedContentAndSwitchBook && !isTeacher;

  useEffect(() => {
    const url = new URL(window.location);
    if (url.searchParams.get('uId')) {
      openContentsSubmenuModalAction();
    }
  }, []);

  useEffect(() => {
    const checkImageUrl = url =>
      new Promise(resolve => {
        const img = new Image();
        img.onload = () => resolve({ url, valid: true });
        img.onerror = () => resolve({ url, valid: false });
        img.src = url;
      });

    (async () => {
      const pages = [];
      let spreadImage = null;
      if (printView) {
        spreadImage = printView.spreadImage;
        if (printView?.firstImage) {
          const checkedImage = await checkImageUrl(`${CONTENT_PATH}/${printView.firstImage}`);
          if (checkedImage.valid) {
            pages.push(printView.firstImage);
          }
        }
        if (printView?.secondImage) {
          const checkedImage = await checkImageUrl(`${CONTENT_PATH}/${printView.secondImage}`);
          if (checkedImage.valid) {
            pages.push(printView.secondImage);
          }
        }
      }

      setPrintViewPages(pages);
      setPrintViewSpreadImage(spreadImage);
    })();
  }, [JSON.stringify(printView)]);

  useEffect(() => {
    onRender();
  }, [onRender, dictionaryEnabled]);

  const handleClosePanel = () => {
    closePanel();
  };

  const changeMenuSide = () => {
    setMenuOrientationAction(
      menuOrientation === MENU_ORIENTATION.LEFT ? MENU_ORIENTATION.RIGHT : MENU_ORIENTATION.LEFT
    );
  };

  const getMenuPosition = () => {
    if (menuOrientation === MENU_ORIENTATION.RIGHT) {
      return `${styles.modalRightMenu}`;
    }
    return `${styles.modalLeftMenu}`;
  };

  const renderContentPlayerSidebarModal = () => {
    const position = getMenuPosition();
    return (
      <div className={position} aria-live="assertive">
        <PopoutPanel
          id="contentPlayerSidebar"
          ariaLabel={`${cptContent.contents} ${cptContent.menu} ${
            isContentPlayerSidebarOpen ? cptContent.open : cptContent.closed
          }`}
          isOpen={isContentPlayerSidebarOpen}
          panelSize="fullWidth"
          className={MODAL_CLASSES.OVERWRITE_LEFT}
        >
          <ContentPlayerSidebar
            title={cptContent.contents}
            cptContent={cptContent}
            isContentPlayerSidebarOpen={isContentPlayerSidebarOpen}
          />
        </PopoutPanel>
      </div>
    );
  };

  const renderResourcesSidebarModal = () => {
    const position = getMenuPosition();
    return (
      <div className={position} aria-live="assertive">
        <PopoutPanel
          id="resourcePlayerSidebar"
          ariaLabel={`${cptContent.contents} ${cptContent.menu} ${
            isResourcesSidebarOpen ? cptContent.open : cptContent.closed
          }`}
          isOpen={isResourcesSidebarOpen}
          panelSize="fullWidth"
          className={MODAL_CLASSES.OVERWRITE_LEFT}
        >
          <ResourcesPlayerSidebar cptContent={cptContent} />
        </PopoutPanel>
      </div>
    );
  };

  const renderPrintViewModal = () => {
    if (!isPrintViewModalOpen) {
      return null;
    }

    return (
      <div className={styles.printViewModal}>
        <PopoutPanel
          id="printViewModal"
          ariaLabel="PrintViewModal"
          isOpen={isPrintViewModalOpen}
          panelSize="fullWidth"
          className={MODAL_CLASSES.OVERWRITE}
        >
          <ContentPlayerPrintView
            title={currentActivity?.title}
            cptContent={cptContent}
            pages={printViewPages}
            spreadImage={printViewSpreadImage}
          />
        </PopoutPanel>
      </div>
    );
  };

  const renderResourcePlayerModal = () => {
    if (!isResourceModalPlayerOpen) {
      return null;
    }

    return <ContentPlayerResourceModal />;
  };

  const renderDictionaryModal = () => {
    if (!isDictionaryModalOpen) {
      return null;
    }

    const TrappedDictionaryModal = withFocusTrap(ContentPlayerDictionary, parentRef);
    return <TrappedDictionaryModal dictionarySearchOrder={dictionarySearchOrder} cptContent={cptContent} />;
  };

  const renderDockMenu = () =>
    productVariant === selfSelectedUserRoles.SELF_TEACHER && (
      <div className={styles.cpMenuSliderSmall}>
        <div
          role="menuitem"
          tabIndex={0}
          aria-label={cptContent.change_menu_side}
          onKeyDown={onKeyDownHandler(changeMenuSide)}
          onClick={changeMenuSide}
          className={styles.cpMenuItem}
        >
          <div className={classnames(EmblemSize.SMALL, EmblemShape.SQUARE, styles.cpMenuIcon)}>
            <SVGIcon
              glyph={
                menuOrientation === MENU_ORIENTATION.LEFT ? GlyphIcon.ICON_DOCK_LEFT_CPT : GlyphIcon.ICON_DOCK_RIGHT_CPT
              }
            />
          </div>
          <span className={styles.cpMenuLabel}>
            {menuOrientation === MENU_ORIENTATION.LEFT ? cptContent.dock_left : cptContent.dock_right}
          </span>
        </div>
      </div>
    );

  const renderContentsButton = () => (
    <div
      role="menuitem"
      tabIndex={0}
      aria-label={cptContent.contents}
      onKeyDown={onKeyDownHandler(toggleContentsSidebarModal)}
      onClick={toggleContentsSidebarModal}
      className={styles.cpMenuItem}
    >
      <div
        className={classnames(
          EmblemSize.SMALL,
          EmblemShape.SQUARE,
          styles.cpMenuIcon,
          isContentPlayerSidebarOpen && styles.cpMenuIconSelected
        )}
      >
        <SVGIcon glyph={GlyphIcon.ICON_CONTENTS_CPT} />
      </div>
      <span className={styles.cpMenuLabel}>{cptContent.contents}</span>
    </div>
  );

  const renderResourcesButton = () => {
    if (resources.foldersWithData.length > 0 && shouldDisplayResourcesFeature) {
      return (
        <div
          role="menuitem"
          tabIndex={0}
          aria-label={cptContent.resources}
          onKeyDown={onKeyDownHandler(toggleResourcesSidebarModal)}
          onClick={toggleResourcesSidebarModal}
          className={styles.cpMenuItem}
        >
          <div
            className={classnames(
              EmblemSize.SMALL,
              EmblemShape.SQUARE,
              styles.cpMenuIcon,
              isResourcesSidebarOpen && styles.cpMenuIconSelected
            )}
          >
            <SVGIcon glyph={GlyphIcon.ICON_RESOURCES_CPT} />
          </div>
          <span className={styles.cpMenuLabel}>{cptContent.resources}</span>
        </div>
      );
    }

    return null;
  };

  const renderPrintViewButton = () => {
    if (shouldDisplayPrintView) {
      if (currentActivity?.printView && printViewPages.length > 0) {
        return (
          <div
            role="button"
            tabIndex={0}
            aria-label={cptContent.print_view}
            onKeyDown={onKeyDownHandler(togglePrintViewModalContent)}
            onClick={togglePrintViewModalContent}
            className={styles.cpMenuItem}
          >
            <div
              className={classnames(
                EmblemSize.SMALL,
                EmblemShape.SQUARE,
                styles.cpMenuIcon,
                isPrintViewModalOpen && styles.cpMenuIconSelected
              )}
            >
              <SVGIcon glyph={GlyphIcon.ICON_PRINT_VIEW_CPT} />
            </div>
            <span className={styles.cpMenuLabel}>{cptContent.print_view}</span>
          </div>
        );
      }

      return (
        <div className={styles.cpMenuItemDisabled}>
          <div className={classnames(EmblemSize.SMALL, EmblemShape.SQUARE, styles.cpMenuIcon)}>
            <SVGIcon glyph={GlyphIcon.ICON_PRINT_VIEW_CPT} />
          </div>
          <span className={styles.cpMenuLabel}>{cptContent.print_view}</span>
        </div>
      );
    }

    return null;
  };

  const handleSwitchBookClick = () => {
    toggleSwitchBookAction();
  };

  const renderSwitchBookDesktopButton = () => {
    if (shouldDisplaySwitchBook) {
      if (isTeacherWithLinkedContentAndSwitchBook) {
        return (
          <div
            className={styles.cpMenuItem}
            onClick={handleSwitchBookClick}
            onKeyDown={onKeyDownHandler(handleSwitchBookClick)}
            role="button"
            tabIndex={0}
          >
            <div
              className={classnames(
                EmblemSize.SMALL,
                EmblemShape.SQUARE,
                styles.cpMenuIcon,
                isSwitchBookOpen && styles.cpMenuIconSelected
              )}
            >
              <SVGIcon glyph={GlyphIcon.ICON_SWITCH_BOOK_CPT} />
            </div>
            <span className={styles.cpMenuLabel}>{cptContent.switch_book}</span>
          </div>
        );
      }

      if (isStudentWithLinkedContentAndSwitchBook) {
        return (
          <div className={styles.cpMenuItemDisabled}>
            <div
              className={classnames(
                EmblemSize.SMALL,
                EmblemShape.SQUARE,
                styles.cpMenuIcon,
                isSwitchBookOpen && styles.cpMenuIconSelected
              )}
            >
              <SVGIcon glyph={GlyphIcon.ICON_SWITCH_BOOK_CPT} />
            </div>
            <span className={styles.cpMenuLabel}>{cptContent.switch_book}</span>
          </div>
        );
      }

      return (
        <div className={styles.cpMenuItemDisabled}>
          <div
            className={classnames(
              EmblemSize.SMALL,
              EmblemShape.SQUARE,
              styles.cpMenuIcon,
              isSwitchBookOpen && styles.cpMenuIconSelected
            )}
          >
            <SVGIcon glyph={GlyphIcon.ICON_SWITCH_BOOK_CPT} />
          </div>
          <span className={styles.cpMenuLabel}>{cptContent.switch_book}</span>
        </div>
      );
    }

    return null;
  };

  const renderSwitchBookMobileButton = () => {
    if (shouldDisplaySwitchBook) {
      if (isTeacherWithLinkedContentAndSwitchBook) {
        return (
          <div>
            <div
              className={styles.cpMenuItem}
              onClick={handleSwitchBookClick}
              onKeyDown={onKeyDownHandler(handleSwitchBookClick)}
            >
              <div className={classnames(EmblemSize.SMALL, EmblemShape.SQUARE, styles.cpMenuIcon)}>
                <SVGIcon glyph={GlyphIcon.ICON_SWITCH_BOOK_CPT} />
              </div>
              <span className={styles.cpMenuLabel}>{cptContent.switch_book}</span>
              <div
                className={isSwitchBookOpen ? `${styles.caratIcon} ${styles.caratIconReverse}` : `${styles.caratIcon}`}
              >
                <SVGIcon className={styles.svgIcon} glyph={GlyphIcon.ICON_DOWN} />
              </div>
            </div>
            <div className={styles.switchBookContainer}>
              <ContentPlayerSwitchBook isSwitchBookOpen={isSwitchBookOpen} cptContent={cptContent} />
            </div>
          </div>
        );
      }

      if (isStudentWithLinkedContentAndSwitchBook) {
        return (
          <div>
            <div className={styles.cpMenuItemDisabled}>
              <div className={classnames(EmblemSize.SMALL, EmblemShape.SQUARE, styles.cpMenuIcon)}>
                <SVGIcon glyph={GlyphIcon.ICON_SWITCH_BOOK_CPT} />
              </div>
              <span className={styles.cpMenuLabel}>{cptContent.switch_book}</span>
              <div
                className={isSwitchBookOpen ? `${styles.caratIcon} ${styles.caratIconReverse}` : `${styles.caratIcon}`}
              >
                <SVGIcon className={styles.svgIcon} glyph={GlyphIcon.ICON_DOWN} />
              </div>
            </div>
            <div className={styles.switchBookContainer}>
              <ContentPlayerSwitchBook isSwitchBookOpen={isSwitchBookOpen} cptContent={cptContent} />
            </div>
          </div>
        );
      }

      return (
        <div className={styles.cpMenuItemDisabled}>
          <div
            className={classnames(
              EmblemSize.SMALL,
              EmblemShape.SQUARE,
              styles.cpMenuIcon,
              isSwitchBookOpen && styles.cpMenuIconSelected
            )}
          >
            <SVGIcon glyph={GlyphIcon.ICON_SWITCH_BOOK_CPT} />
          </div>
          <span className={styles.cpMenuLabel}>{cptContent.switch_book}</span>
        </div>
      );
    }

    return null;
  };

  const renderDictionaryButton = () => {
    if (dictionaryEnabled) {
      return (
        <div
          role="menuitem"
          tabIndex={0}
          aria-label={cptContent.dictionary}
          onKeyDown={onKeyDownHandler(toggleDictionaryModalContent)}
          onClick={toggleDictionaryModalContent}
          className={styles.cpMenuItem}
        >
          <div className={classnames(EmblemSize.SMALL, EmblemShape.SQUARE, styles.cpMenuIcon)}>
            <SVGIcon glyph={GlyphIcon.ICON_DICTIONARY_CPT} />
          </div>
          <span className={styles.cpMenuLabel}>{cptContent.dictionary}</span>
        </div>
      );
    }

    return (
      <div className={styles.cpMenuItemDisabled}>
        <div className={classnames(EmblemSize.SMALL, EmblemShape.SQUARE, styles.cpMenuIcon)}>
          <SVGIcon glyph={GlyphIcon.ICON_DICTIONARY_CPT} />
        </div>
        <span className={styles.cpMenuLabel}>{cptContent.dictionary}</span>
      </div>
    );
  };

  return (
    <div className={menuOrientation === MENU_ORIENTATION.LEFT ? `${styles.containerLeft}` : `${styles.containerRight}`}>
      <div className={`${styles.cpMenu} ${styles.cpDesktopView} ${styles.cpSmall}`} role="menu">
        <div className={styles.cpMenuParent}>
          {renderDockMenu()}
          {renderContentsButton()}
          {renderResourcesButton()}
          {renderPrintViewButton()}
          {renderSwitchBookDesktopButton()}
          {renderDictionaryButton()}
        </div>
      </div>

      <div className={styles.cpMobileView}>
        <div className={classnames(styles.cpMenuItem, styles.cpCloseMenuButton, styles.cpHeaderRow)}>
          <button onClick={handleClosePanel} type="button">
            <div className={classnames(styles.emblem, EmblemSize.MEDIUM, styles.cpCloseButton)}>
              <SVGIcon glyph={GlyphIcon.ICON_CLOSE} />
            </div>
          </button>
        </div>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={onKeyDownHandler(toggleContentsSidebarModal)}
          onClick={toggleContentsSidebarModal}
          className={styles.cpMenuItem}
        >
          <div className={classnames(EmblemSize.SMALL, EmblemShape.SQUARE, styles.cpMenuIcon)}>
            <SVGIcon glyph={GlyphIcon.ICON_CONTENTS_CPT} />
          </div>
          <span className={styles.cpMenuLabel}>{cptContent.contents}</span>
        </div>
        {shouldDisplayResourcesFeature && (
          <div
            role="button"
            tabIndex={0}
            onKeyDown={onKeyDownHandler(toggleResourcesSidebarModal)}
            onClick={toggleResourcesSidebarModal}
            className={styles.cpMenuItem}
          >
            <div className={classnames(EmblemSize.SMALL, EmblemShape.SQUARE, styles.cpMenuIcon)}>
              <SVGIcon glyph={GlyphIcon.ICON_RESOURCES_CPT} />
            </div>
            <span className={styles.cpMenuLabel}>{cptContent.resources}</span>
          </div>
        )}
        {currentActivity?.printView && shouldDisplayPrintView && printViewPages.length > 0 && (
          <div
            role="button"
            tabIndex={0}
            aria-label={cptContent.printView}
            onKeyDown={onKeyDownHandler(togglePrintViewModalContent)}
            onClick={togglePrintViewModalContent}
            className={styles.cpMenuItem}
          >
            <div className={classnames(EmblemSize.SMALL, EmblemShape.SQUARE, styles.cpMenuIcon)}>
              <SVGIcon glyph={GlyphIcon.ICON_PRINT_VIEW_CPT} />
            </div>
            <span className={styles.cpMenuLabel}>{cptContent.print_view}</span>
          </div>
        )}
        {(!currentActivity?.printView || printViewPages.length === 0) && shouldDisplayPrintView && (
          <div className={styles.cpMenuItemDisabled}>
            <div className={classnames(EmblemSize.SMALL, EmblemShape.SQUARE, styles.cpMenuIcon)}>
              <SVGIcon glyph={GlyphIcon.ICON_PRINT_VIEW_CPT} />
            </div>
            <span className={styles.cpMenuLabel}>{cptContent.print_view}</span>
          </div>
        )}
        {renderSwitchBookMobileButton()}
        <div
          role="button"
          tabIndex={0}
          aria-label={cptContent.dictionary}
          onKeyDown={onKeyDownHandler(toggleDictionaryModalContent)}
          onClick={toggleDictionaryModalContent}
          className={styles.cpMenuItem}
        >
          <div className={classnames(EmblemSize.SMALL, EmblemShape.SQUARE, styles.cpMenuIcon)}>
            <SVGIcon glyph={GlyphIcon.ICON_DICTIONARY_CPT} />
          </div>
          <span className={styles.cpMenuLabel}>{cptContent.dictionary}</span>
        </div>
      </div>
      {renderContentPlayerSidebarModal()}
      {renderResourcesSidebarModal()}
      {renderPrintViewModal()}
      {renderDictionaryModal()}
      {renderResourcePlayerModal()}
    </div>
  );
}

ContentPlayerMenu.propTypes = {
  onRender: PropTypes.func.isRequired,
  menuOrientation: PropTypes.oneOf([MENU_ORIENTATION.LEFT, MENU_ORIENTATION.RIGHT]),
  closePanel: PropTypes.func,
  setMenuOrientationAction: PropTypes.func,
  productVariant: PropTypes.string,
  dictionaryEnabled: PropTypes.bool,
  dictionarySearchOrder: PropTypes.string,
  isContentPlayerSidebarOpen: PropTypes.bool.isRequired,
  isResourcesSidebarOpen: PropTypes.bool.isRequired,
  isPrintViewModalOpen: PropTypes.bool.isRequired,
  isDictionaryModalOpen: PropTypes.bool.isRequired,
  toggleContentsSidebarModal: PropTypes.func.isRequired,
  toggleResourcesSidebarModal: PropTypes.func.isRequired,
  togglePrintViewModalContent: PropTypes.func.isRequired,
  toggleDictionaryModalContent: PropTypes.func.isRequired,
  currentActivity: PropTypes.object,
  printView: PropTypes.object,
  cptContent: PropTypes.object,
  resources: PropTypes.object,
  features: PropTypes.object,
  parentRef: PropTypes.object,
  toggleSwitchBookAction: PropTypes.func,
  isSwitchBookOpen: PropTypes.bool,
  openContentsSubmenuModalAction: PropTypes.func,
  isResourceModalPlayerOpen: PropTypes.bool
};

const mapStateToProps = ({
  structuredContentPlayer: {
    isContentPlayerSidebarOpen,
    isResourcesSidebarOpen,
    menuOrientation,
    isPrintViewModalOpen,
    isDictionaryModalOpen,
    navigation,
    productVariant,
    dictionaryEnabled,
    dictionarySearchOrder,
    resources,
    features,
    isSwitchBookOpen,
    isResourceModalPlayerOpen,
    isMobileGamePopupOpen
  }
}) => ({
  isContentPlayerSidebarOpen,
  isPrintViewModalOpen,
  isDictionaryModalOpen,
  isResourcesSidebarOpen,
  menuOrientation,
  navigation,
  currentActivity: navigation.currentActivities[navigation.currentActivityIndexInLesson],
  printView: navigation.currentActivities[navigation.currentActivityIndexInLesson]?.printView,
  productVariant,
  dictionaryEnabled,
  dictionarySearchOrder: dictionarySearchOrder?.split(','),
  resources,
  features,
  isSwitchBookOpen,
  isResourceModalPlayerOpen,
  isMobileGamePopupOpen
});

const mapDispatchToProps = {
  setCurrentContentData: setCurrentContent,
  toggleContentsSidebarModal: toggleContentsSubmenuModal,
  toggleResourcesSidebarModal: toggleResourcesSubmenuModal,
  togglePrintViewModalContent: togglePrintViewModal,
  toggleDictionaryModalContent: toggleDictionaryModal,
  setMenuOrientationAction: setMenuOrientation,
  toggleSwitchBookAction: toggleSwitchBook,
  openContentsSubmenuModalAction: openContentsSubmenuModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ContentPlayerMenu);
