import { fork, put, select } from 'redux-saga/effects';
import envSettings from '../../../../globals/envSettings';
import userRoles, { roleIsAtLeast, isOupContent } from '../../../../globals/userRoles';
import { appReady } from '../../../reducers/app.reducer';
import activityRootSaga from './activity';
import handleLogout from './auth/handleLogout.saga';
import handleOrgSwitch from './auth/handleOrgSwitch.saga';
import addStudentsToClassroom from './classroom/addStudentsToClassroom';
import addTeachersToClassroom from './classroom/addTeachersToClassroom';
import classroomArchive from './classroom/archive';
import classPageLoader from './classroom/classPageLoader.saga';
import classroomCreate from './classroom/create';
import placementTestSession from './placementOnboardingWizard';
import loadPlacementTests from './placementTests';
import loadPlacementTestResults from './placementTestResults';
import loadEnrolUserSaga from './selfRegisteredUserOnboardingWizard';
import classroomEdit from './classroom/edit';
import studentRequestToJoinAClass from './classroom/studentRequestToJoinAClass';
import manageJoinAClassRequest from './classroom/manageJoinAClassRequest';
import removeStudentsFromClassroom from './classroom/removeStudentsFromClassroom';
import removeTeachersFromClassroom from './classroom/removeTeachersFromClassroom';
import removeMultipleUsersFromClassroom from './classroom/removeMultipleUsersFromClassroom';
import dataRecencyRootSaga from './dataRecency';
import editOrgRootSaga from './editOrg';
import editUserRootSaga from './editUser';
import getGradebookClassReport from './gradebook/getGradebookClassReport';
// eslint-disable-next-line import/no-named-as-default
import getSchoolDialReport from './gradebook/getSchoolDialReport';
import getNotifications from './gradebook/getNotifications';
import markNotificationAsViewed from './gradebook/markNotificationAsViewed';
import gradebookPageLoader from './gradebook/gradebookPageLoader.saga';
import gradedReadersPageLoader from './gradebook/gradedReadersPageLoader.saga';
import savedSettingsLoader from './gradebook/settingsLoader.saga';
import importUsersRootSaga from './importUsers';
import informationPopupRootSaga from './informationPopup';
import assignLearningMaterial from './library/assignLearningMaterial/assignLearningMaterial.saga';
import bulkUploadToLibrary from './library/bulkUpload/bulkUploadToLibrary.saga';
import recallLicence from './library/recallLicence.saga';
import removeLearningMaterial from './library/removeLearningMaterial/removeLearningMaterial.saga';
import singleInputToLibrary from './library/singleInput/singleInputToLibrary.saga';
import managedUserRootSaga from './managedUser';
import managedUserChangeUsernameRootSaga from './managedUserChangeUsername';
import managedUserEditRootSaga from './managedUserEdit';
import orgRootSaga from './org';
import bulkUploadToClass from './organisation/bulkEnrolUser/bulkUploadToClass.saga';
import enrolUser from './organisation/enrolUser/enrolUser.saga';
import orgPageLoader from './organisation/orgPageLoader.saga';
import resetPasswordRootSaga from './resetPassword';
import resendInvitationRootSaga from './resendInvitation';
import search from './searchAndSelection/search.saga';
import userRootSaga from './user';
import archiveUsersFromOrg from './user/archiveUsersFromOrg.saga';
import invitationsBanner from './user/invitationsBanner.saga';
import restoreOrgUsers from './user/restoreOrgUsers.saga';
import userEnrolRootSaga from './userEnrol';
import studentReport from './studentReport';
import teacherResources from './teacherResources';
import product from './product';
import ngsHomePage from './ngsHomePage';
import ngsTeacherPages from './ngsTeacherPages';
import ngsStudentPages from './ngsStudentPages';
import ngsCoursePages from './ngsCoursePages';
import ngsLTIOpener from './ngsLTIOpener';
import { postNotificationMessage } from '../../../actions/postMessage';
import singleInputToProfile from './profile/singleInput/singleInputToProfile.saga';
import checkUsernameSaga from './managedUserEdit/checkUsername.saga';
import managedUserSignInCardSaga from './user/managedUserSignInCard.saga';
import watchPollingSaga from '../../utils/pollingSaga';
import triggerDownloadSigninCardProcess from './user/downloadSigninCardProcess.saga';
import orbRootSaga from './orb';
import hubDashboard from './hubDashboard';
import preseedOrg from './preseeding';
import assessments from './assessments';
import adaptedTests from './adaptedTests';
import oteContent from './ote';
import joinClass from './classroom/joinClass.saga';
import csvUserUpload from './csvUserUpload';
import csvProductStructureUpload from './csvProductStructureUpload';
import downloadStudentData from './downloadStudentData/downloadStudentData.saga';
import productUploadLogsData from './productUploadLogs/productUploadLogs.saga';
import productUploadData from './productUpload/productUpload.saga';
import productUploadCheckData from './productUploadCheck/productUploadCheck.saga';
import getEReaderProducts from './product/getEReaderProducts.saga';
import productPublish from './productPublish/productPublish.saga';
import generateReport from './generateReport/generateReport.saga';
import generateSchoolReport from './generateSchoolReport/generateSchoolReport.saga';
import invoiceReport from './invoiceReport/InvoiceReport.saga';
import deferredEmails from './deferredEmails';
import sendOicEmails from './sendOicEmails';
import systemNotification from './systemNotification';
import classOnboardingWizard from './classOnboardingWizard';
import placementTestSessionCreate from './placementOnboardingWizard/placementTestSession';
import loadCptContent from './cpt';
import userLicenceRemove from './userLicenceRemove';
import lmsConfig from './lmsConfig';
import matReportSummary from './matReportSummary';
import productFinder from './productFinder';
import productLocks from './productLocks';
import postContentPackage from './postContentPackage';
import searchUsers from './searchUsers';
import impersonateUser from './impersonateUser';
import courseUpload from './courseUpload';
import coursePublish from './coursePublish';
import generateUsername from './generateUsername';
import reCAPTCHAExample from './reCAPTCHAExample';
import regenerateSupervisedUserSignInCard from './supervisedUser';
import searchUserEdit from './adminDashboard';
import inspectionCopy from './inspectionCopy';
import userPlatformStatistics from './userPlatformStatistics';
import recentlyAccessed from './recentlyAccessed';
import bulkPasswordReset from './bulkPasswordReset';
import wordOfTheDay from './wordOfTheDay';
import orgUsage from './orgUsage';
import getJoiningCodeDetailsAndCredits from './getJoiningCodeDetailsAndCredits';
import updateJoiningCode from './updateJoiningCode';
import updateSessionPlaces from './updateTestSessionPlaces';

export default function* authorisedFunctions() {
  console.groupCollapsed('[authorisedFunctions]');
  console.log('[authorisedFunctions] Starting functions...');

  yield fork(handleLogout);
  yield fork(handleOrgSwitch);
  yield fork(oteContent);
  yield fork(invitationsBanner);

  // We should start the partial user reg code here
  // yield fork(registration);

  // Assessments
  yield fork(assessments);
  // Generic search handlers
  yield search();
  yield fork(adaptedTests);
  yield fork(informationPopupRootSaga);
  yield fork(activityRootSaga);
  yield fork(dataRecencyRootSaga);
  yield fork(editOrgRootSaga);
  yield fork(editUserRootSaga);
  yield fork(importUsersRootSaga);
  yield fork(managedUserRootSaga);
  yield fork(managedUserChangeUsernameRootSaga);
  yield fork(managedUserEditRootSaga);
  yield fork(orgRootSaga);
  yield fork(resetPasswordRootSaga);
  yield fork(userEnrolRootSaga);
  yield fork(resendInvitationRootSaga);
  yield fork(product);
  yield fork(checkUsernameSaga);
  yield fork(preseedOrg);
  yield fork(deferredEmails);
  yield fork(sendOicEmails);
  yield fork(userLicenceRemove);
  yield fork(matReportSummary);
  yield fork(invoiceReport);
  yield fork(reCAPTCHAExample);
  yield fork(searchUserEdit);

  // Teacher resources panel
  yield fork(teacherResources);
  yield fork(studentRequestToJoinAClass);

  // generate schoolReport

  yield fork(generateSchoolReport);

  const currentUserRole = yield select(state => state.identity.role);

  if (roleIsAtLeast(userRoles.USER, currentUserRole)) {
    yield fork(singleInputToProfile);
    yield fork(getGradebookClassReport);
    yield fork(savedSettingsLoader);
  }

  yield fork(joinClass);

  yield fork(downloadStudentData);

  if (roleIsAtLeast(userRoles.OUP_SUPPORT, currentUserRole)) {
    yield fork(systemNotification);
    yield fork(searchUsers);
    yield fork(impersonateUser);
  }

  if (isOupContent(currentUserRole)) {
    yield fork(productUploadLogsData);
    yield fork(productUploadData);
    yield fork(productUploadCheckData);
    yield fork(productPublish);
    yield fork(generateReport);
    yield fork(getEReaderProducts);
  }

  if (roleIsAtLeast(userRoles.TEACHER_ADMIN, currentUserRole)) {
    yield fork(singleInputToLibrary);
    yield fork(bulkUploadToLibrary);
    yield fork(generateUsername);
  }

  if (roleIsAtLeast(userRoles.TEACHER, currentUserRole)) {
    // Org admin
    yield fork(orgPageLoader);
    yield fork(enrolUser);
    yield fork(bulkUploadToClass);
    yield fork(bulkPasswordReset);

    yield fork(userRootSaga);

    // Archive / Restore Users
    yield fork(archiveUsersFromOrg);
    yield fork(restoreOrgUsers);
    yield fork(manageJoinAClassRequest);
    // Sign In card details
    yield fork(managedUserSignInCardSaga);

    // Classroom Admin
    yield fork(classroomCreate);
    yield fork(classPageLoader);
    yield fork(classroomEdit);
    yield fork(classroomArchive);
    yield fork(addStudentsToClassroom);
    yield fork(addTeachersToClassroom);
    yield fork(removeStudentsFromClassroom);
    yield fork(removeTeachersFromClassroom);
    yield fork(removeMultipleUsersFromClassroom);

    // Assignments
    yield fork(assignLearningMaterial);
    yield fork(removeLearningMaterial);
    yield fork(recallLicence);

    // Gradebook development page loader

    yield fork(getSchoolDialReport);
    yield fork(gradedReadersPageLoader);
    yield fork(gradebookPageLoader);
    yield fork(studentReport);

    // NGS
    yield fork(ngsTeacherPages);

    // Polling
    yield fork(watchPollingSaga);

    // downloadSigninCardProcess
    yield fork(triggerDownloadSigninCardProcess);

    // ORB
    yield fork(orbRootSaga);

    // Supervised User
    yield fork(regenerateSupervisedUserSignInCard);
  }

  // NGS
  yield fork(ngsHomePage);
  yield fork(ngsCoursePages);
  yield fork(ngsStudentPages);
  yield fork(ngsLTIOpener);

  // Notifications
  yield fork(getNotifications);
  yield fork(markNotificationAsViewed);

  // HUB
  yield fork(hubDashboard);
  yield fork(classOnboardingWizard);
  yield fork(placementTestSession);
  yield fork(placementTestSessionCreate);
  yield fork(loadPlacementTests);
  yield fork(loadPlacementTestResults);
  yield fork(loadEnrolUserSaga);
  yield fork(productFinder);
  yield fork(productLocks);
  yield fork(recentlyAccessed);
  yield fork(wordOfTheDay);
  yield fork(orgUsage);

  yield fork(getJoiningCodeDetailsAndCredits);
  yield fork(updateJoiningCode);
  yield fork(updateSessionPlaces);
  // CPT
  yield fork(loadCptContent);

  // Dashboard Csv User Upload
  yield fork(csvUserUpload);

  // Dashboard Csv Product Structure  Upload
  yield fork(csvProductStructureUpload);

  // LMSConfig
  yield fork(lmsConfig);

  // postContentPackage
  yield fork(postContentPackage);

  // Course
  yield fork(courseUpload);
  yield fork(coursePublish);

  // Inspection Copy
  yield fork(inspectionCopy);

  yield fork(userPlatformStatistics);

  console.log('[authorisedFunctions] Complete');

  console.log('[authorisedFunctions] Signalling for page to render...');
  yield put(appReady());

  yield put(postNotificationMessage({ eventName: 'APP_READY', viewId: envSettings.embeddedViewId }));

  console.groupEnd();
}
