const EMAIL_DATE_FORMAT = 'D MMMM YYYY';
const ENTITIES = {
  ASSIGNMENT: 'assignment',
  EXAMPLE: 'example',
  LEARNING_RECORD: 'learningRecord',
  LEARNING_RECORD_ARCHIVE: 'learningRecordArchive',
  LEARNING_RECORD_CONFIG: 'learningRecordConfig',
  ENTITLEMENT: 'entitlement',
  ENV_PRODUCT: 'envProduct',
  ENV_PRODUCT_GROUP: 'envProductGroup',
  PRODUCT: 'product',
  PRODUCT_STRUCTURE: 'productStructure',
  USER: 'user',
  USER_LICENSE: 'userLicense',
  ORG_LICENSE: 'orgLicense',
  ACTIVATION_CODE: 'activationCode',
  ACTIVATION_CODE_BATCH: 'activationCodeBatch',
  ORG: 'organization',
  CLASS: 'class',
  COHORT: 'cohort',
  USER_ENROLMENT: 'userEnrolment',
  USER_TERMS_AND_CONDITIONS: 'userTermsAndConditions',
  GROUP: 'group',
  ORG_GROUP: 'orgGroup',
  MEMBERSHIP: 'membership',
  GROUP_USER: 'groupUser',
  ATLAS_ENROLMENT_ORGS: 'atlasEnrolmentOrgs',
  USER_ENGAGEMENT: 'userEngagement',
  ATLAS_ENROLMENT_EXCLUDED_ORGS: 'atlasEnrolmentExcludedOrgs',
  ORG_USER_ROLES: 'orgUserRoles',
  USER_PLATFORM_METADATA: 'userPlatformMetadata',
  USER_PLATFORM_SENSITIVE_METADATA: 'userPlatformSensitiveMetadata',
  JOINING_CODES: 'joiningCodes',
  ORG_CODE_POOL: 'orgCodePool',
  USER_PRODUCT_LINK: 'userProductLink',
  USER_PRODUCT_LINKS: 'userProductLinks',
  GROUP_PRODUCT_LINK: 'groupProductLink',
  COURSE_ALLOCATION: 'courseAllocation',
  IMPORT_PROCESS: 'importProcess',
  SCHEDULED_JOBS: 'scheduledJobs',
  ORG_CONFIG: 'orgConfig',
  UNSENT_EMAIL_INVITES: 'unsentEmailInvites',
  PLAYER_LAUNCHES: 'playerLaunches',
  USER_CONTENT_STATE: 'userContentState',
  USER_STATEMENTS: 'userStatements',
  AM_MIGRATION_TEST_CENTRE: 'testCentre',
  AM_MIGRATION_USER: 'user',
  USER_INVITATIONS: 'userInvitations',
  LMS: 'lms',
  OIC_TEST_CREDITS: 'oicTestCredits',
  CLASS_CONFIG: 'classConfig',
  MANAGED_USER_DETAILS: 'managedUserDetails',
  BULK_UPLOAD_MANAGED_USER: 'bulkUploadManagedUser',
  USER_METADATA: 'userMetadata',
  CONTENT_EVENTS: 'contentEvents',
  PURCHASE_TRANSACTIONS: 'purchaseTransactions',
  VST_USER_LICENCE: 'vstUserLicence',
  USER_TEMPORARY_CREDENTIAL_HASHES: 'userTemporaryCredentialHashes'
};

const ARIA_ENTITIES = {
  DATA_MIGRATION_STATS: 'ariaDataMigrationStats'
};

const CONTENT_ENTITIES = {
  RESOURCE: 'resource',
  COURSE: 'course',
  COURSE_EXPANDED: 'courseExpanded',
  METADATA: 'metadata',
  PRODUCT_DEFINITION: 'productDefinition',
  CONTENT_EXAMPLE: 'example'
};

const EPS_EVENT_TYPES = {
  PRODUCT: 'PRODUCT',
  PRODUCT_STRUCTURE: 'PRODUCT_STRUCTURE',
  PRODUCT_DEFINITION: 'PRODUCT_DEFINITION',
  COURSE: 'COURSE'
};

const EPS_EVENT_ACTIONS = {
  CREATE: 'CREATE',
  MODIFY: 'MODIFY',
  DELETE: 'DELETE',
  PUBLISH: 'PUBLISH',
  INSERT: 'INSERT'
};

const HTTP_METHOD_TYPE = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
  HEAD: 'HEAD',
  OPTIONS: 'OPTIONS',
  CONNECT: 'CONNECT',
  TRACE: 'TRACE'
};

const CONTENT_SOURCES = {
  EPS: 'EPS',
  ELT_AUTHOR: 'ELT_AUTHOR',
  LOR: 'LOR',
  GAMES: 'GAMES',
  WEBLINKS: 'WEBLINKS'
};

const LOR_ENTITIES = {
  LOR_RESOURCE: 'lorResource',
  LOR_RESOURCE_ASSOCIATION: 'lorResourceAssociation'
};

const EREADER_ENTITIES = {
  EREADER_BOOK: 'ereaderBook',
  EREADER_ANNOTATION: 'ereaderAnnotation',
  EREADER_BOOKMARK: 'ereaderBookmark',
  EREADER_CONFIG_CHANGESET: 'ereaderConfigChangesets',
  EREADER_LOCALE: 'ereaderLocales',
  EREADER_LTI_CONSUMER: 'ereaderLtiConsumer',
  EREADER_USER_BOOK_PAGE: 'ereaderUserBookPage',
  EREADER_USER_INTERJECTION: 'ereaderUserInterjection',
  EREADER_USER_PRINT_HISTORY: 'ereaderUserPrintHistory',
  EREADER_USER_RESET_LEVEL: 'ereaderUserResetLevel',
  EREADER_USER_CONTENT_COPY_USAGE: 'ereaderUserContentCopyUsage',
  EREADER_CONTENT_USER: 'ereaderContentUser',
  EREADER_PUBLISH_JOB: 'ereaderPublishJob',
  EREADER_EXAMPLE: 'ereaderExample'
};

const EREADER_REPORT_ENTITIES = {
  EREADER_USER_REPORT: 'ereaderUserReport',
  EREADER_MEDIA_REPORT: 'ereaderMediaReport',
  EREADER_QUIZ_REPORT: 'ereaderQuizReport'
};

const EREADER_ANOTATION_TYPES = {
  BOOKMARK: 1,
  ANNOTATION: 3
};

const EREADER_ANNOTATION_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};

const DATA_CATEGORIES = {
  PLATFORM: 'ENVIRONMENTAL', // i.e. UAT data cannot be seen by PROD, PREPROD cannot see DEV etc
  CONTENT: 'UNIVERSAL', // i.e. all data is non-sensitive and can be read from anywhere i.e. DEV and PROD both see the same record
  EXTERNAL_LOR: 'EXTERNAL_LOR', // a special case connection to an external Mongo Atlas database which is READ ONLY to us
  EREADER: 'EREADER' // a dedicated database for storing data for the eReader application
};

/**
 * WARNING: these values are used as prefixes for database collections
 * Do not change them unless you have a migration strategy
 */
const CONTENT_STATES = {
  PUBLISHED: 'published', // LOR calls this "live". Not equivalent to PROD. Certainly published content is seen on PROD but it may be seen elsewhere.
  STAGED: 'staged' // LOR calls this "test". Does not mean "Staging" or WIP. This is content in a publish that is a preview before publish. Usually seen at UAT or below.
};

const EPS_CONTENT_DEFAULT_ASSET_ENV = {
  PUBLISHED: 'PROD',
  STAGED: 'UAT'
};

const CONTENT_DATABASES = {
  DEFAULT: 'content-views', // for content as used by all envs
  SANDBOX: 'content-sandbox' // for testing content database processes only
};

const READ_MODES = {
  REPLICA: 'replica', // reads using the nearest read replica
  PRIMARY: 'primary', // forces a read from the primary, necessary when writing to a view then reading the output
  FEDERATION: 'federation' // reads from a Mongo Atlas Federation
};

const WRITE_MODES = {
  DEFAULT: 'default', // writes to the named collection
  PUBLISH: 'publish' // publishes data from a staged collection to its published equivalent
};

const WRITE_CONCERN = {
  DEFAULT: { w: 'majority' },
  W1: { w: 1 }
};

const BULK_OPERATIONS = {
  DEFAULT: 'insert',
  DELETE: 'delete',
  INSERT: 'insert',
  UPDATE: 'update',
  UPSERT: 'upsert'
};

const STATUS_TYPE = {
  SUCCESS: 'SUCCESS',
  PARTIAL_SUCCESS: 'PARTIAL_SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  SCHEDULED: 'SCHEDULED',
  FAILED: 'FAILED'
};

const RESPONSE_STATUS = {
  ERROR: 'error',
  SUCCESS: 'success',
  FAIL: 'fail'
};

const SCHEMA_LEVELS = {
  ERROR: 'error',
  WARN: 'warn'
};

const ORG_STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED'
};

const ORG_REGISTRATION_STATUS = {
  REGISTERED: 'REGISTERED',
  PARTIALLY_REGISTERED: 'PARTIALLY_REGISTERED'
};

const OUP_EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@oup\.com$/;
const OLB_UID_REGEX = /^olb:[0-9A-Z]+-[0-9a-zA-Z]+$/;
const TOKEN_TYPE = {
  REGISTRATION: 'REGISTRATION',
  UPDATE_AUTH: 'UPDATE_AUTH',
  TOKEN_TYPE_PASSWORD_RESEST: 'PASSWORD_RESET'
};

const TOKEN_ERRORS_TYPE = {
  TOKEN_NOT_FOUND: 'TOKEN_NOT_FOUND',
  TOKEN_ALREADY_USED: 'TOKEN_ALREADY_USED',
  USER_NOT_FOUND: 'USER_NOT_FOUND'
};

const GROUP_TYPE = {
  ORGANISATION: 'ORGANISATION',
  CLASS: 'CLASS',
  COHORT: 'COHORT',
  ASSIGNMENT_GROUP: 'ASSIGNMENT_GROUP',
  OUP_MASTER_GROUP: 'OUP_MASTER_GROUP',
  ORGANIZATION: 'organization',
  ORG: 'ORG'
};

const GROUP_STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED'
};

const CLASS_STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  INPROGRESS: 'IN_PROGRESS',
  SCHEDULED: 'SCHEDULED'
};

const CLASS_TYPE = {
  PLACEMENT_GROUP: 'PLACEMENT_GROUP'
};

const OPT_MAIN_FEATURES = 'opt-main-features';
const ORG_MAX_CREDITS = 1000000;

const USER_TYPE = {
  CUSTOMER: 'CUSTOMER'
};

const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED'
};

const USER_REGISTRATION_STATUS = {
  REGISTRATION_PENDING: 'REGISTRATION_PENDING',
  REGISTRATION_COMPLETE: 'REGISTRATION_COMPLETE'
};

const USER_ROLES = {
  TEACHER: 'TEACHER',
  TEACHER_ADMIN: 'TEACHER_ADMIN',
  LEARNER: 'LEARNER',
  MANAGED_USER: 'MANAGED_USER',
  ORG_ADMIN: 'ORG_ADMIN',
  OUP_ADMIN: 'OUP_ADMIN',
  OUP_SUPPORT: 'OUP_SUPPORT'
};

const DATA_SOURCES = {
  EAC: 'EAC',
  MONGODB: 'MONGODB',
  EPS: 'EPS'
};

const EAC_ORGANIZATION_ARCHIVED = '2107';
const EAC_ERROR_FETCHING_USERS_OF_GROUP = '1388';
const EAC_ERROR_FETCHING_LICENSES = '2061';
const EAC_ERROR_FETCHING_MEMBERSHIP = '3163';

// errorMap values should map to CMS keys
const EAC_ORGCODEPOOL_ERRORCODES = {
  3060: 'activation_code_nonexistent',
  3120: 'activation_code_invalid',
  3000: 'product_data_notfound',
  3115: 'activation_code_assigned', // Activation code has already been used
  3077: 'activation_code_expired',
  3157: '3157', // Product with multiple activations
  2053: '2053' // Activation code can not be redeem more than allowed usage
};

const ORG_CODE_POOL_STATUS = {
  OPEN: 'OPEN',
  ASSIGNED: 'ASSIGNED',
  REDEEMED: 'REDEEMED',
  ARCHIVED: 'ARCHIVED'
};

const MEMBERSHIP_STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED'
};

const ENTITLEMENT_TYPE = {
  LICENSE: 'LICENSE',
  LEARNING_ASSIGNMENT: 'LEARNING_ASSIGNMENT',
  SUBSCRIPTION: 'SUBSCRIPTION',
  ALL: 'ALL'
};

const ENTITLEMENT_STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED'
};

const ORG_REGISTRATION_CONTEXT = {
  OUP_CUSTOMER_SUPPORT: 'OUP_CUSTOMER_SUPPORT',
  CES: 'CES'
};

const DELIMITERS = [',', ';', '|', '\t'];

const INVITATION_STATUS = {
  NONE: 'NONE',
  ACCEPTED: 'ACCEPTED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  REMINDER: 'REMINDER',
  EXPIRED: 'EXPIRED',
  REQUESTED: 'REQUESTED',
  DISMISS: 'DISMISS',
  CANCELLED: 'CANCELLED',
  // Adding ARCHIVED status for both PENDING and ACCEPTED users, who are removed from Org, as per EAC API
  ARCHIVED: 'ARCHIVED'
};

const TOKEN_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};

const PLATFORMS = {
  CES: 'CES',
  OALD: 'OALD',
  OLB: 'OLB',
  ORB: 'ORB',
  VST: 'VST',
  HUB: 'HUB',
  OTE: 'OTE',
  OPT: 'OPT',
  OIC: 'OIC',
  SMART: 'SMART',
  KERBOODLE: 'KERBOODLE',
  CMDP: 'CMDP',
  CBA: 'CBA',
  RVS: 'RVS',
  OCP: 'OCP',
  ELTCORE: 'ELTCORE',
  ELTCOURSE: 'ELTCOURSE',
  LTI_HUB: 'LTI_HUB'
};

const PLATFORM_CODES = {
  CES: 'ces',
  OLB: 'olb',
  ORB: 'orb',
  VST: 'vst',
  HUB: 'hub',
  OTE: 'ote',
  OPT: 'opt',
  SMART: 'smart',
  KERBOODLE: 'kerboodle',
  CMDP: 'cmdp',
  CBA: 'cba',
  OUP: 'oup', // unknown OUP platform context, e.g. when T&Cs accepted but context not saved"
  RVS: 'rvs',
  OCP: 'ocp',
  ELTCORE: 'eltcore',
  ELTCOURSE: 'eltcourse',
  OIC: 'oic',
  INSP: 'insp',
  OALD: 'oald',
  LTI_HUB: 'lti_hub'
};

const PLATFORM_SYSTEM_ID = {
  OLB: 'elt_olb',
  VST: 'elt_vst',
  CBA: 'elt_hub'
};

const NO_COUNTRY_CODE = 'ZZ';
const NO_COUNTRY_CODE_REGEX = /[zZ]{2}/;

const API_VERSIONS = {
  // api version declarations
  API_VERSION_BASE: 'application/json', // base api version used before versioning impl
  API_VERSION_0_1: 'application/x.oup-com.eps.v0.1+json',
  API_VERSION_0_2: 'application/x.oup-com.eps.v0.2+json',
  API_VERSION_0_3: 'application/x.oup-com.eps.v0.3+json',
  API_VERSION_LATEST: 'application/x.oup-com.eps+json'
};

const COHORTS = {
  NO_COHORT: 'NO_COHORT',
  NO_YEAR_GROUP: null
};

const LICENSE_TIME_PERIOD = {
  YEAR: 'year',
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day',
  HOUR: 'hour',
  MINUTE: 'minute',
  SECOND: 'second',
  MILLISECOND: 'millisecond'
};

const TIME_UNIT = {
  DAYS: 'days',
  HOURS: 'hours',
  MINUTES: 'minutes',
  YEARS: 'years',
  MONTHS: 'months',
  WEEKS: 'weeks',
  SECONDS: 'seconds',
  MILLISECONDS: 'milliseconds'
};
const LICENSE_TYPE = {
  CONCURRENT: 'CONCURRENT',
  ROLLING: 'ROLLING',
  USAGE: 'USAGE'
};

const LICENSE_EXPIRY_DATES = {
  NEVER: 'Never',
  STUDENT_LICENCE_DURATION: 18
};

const LICENSE_MAPPING = {
  CONCURRENT: { text: 'CONCURRENT', value: 1 },
  ROLLING: { text: 'ROLLING', value: 2 },
  USAGE: { text: 'USAGE', value: 6 }
};

const UNITTYPE_MAPPING = {
  1: 'year',
  2: 'month',
  3: 'week',
  5: 'day',
  10: 'hour',
  12: 'minute',
  13: 'second',
  14: 'millisecond'
};

const BEGINON_MAPPING = {
  1: 'first_use',
  2: 'creation'
};

const LICENSE_BEINGON = {
  FIRST_USE: 'first_use',
  CREATION: 'creation'
};

const LICENSE_STATUS = {
  VALID: 'VALID',
  NOT_VALID: 'NOT_VALID',
  AVAILABLE: 'AVAILABLE',
  REDEEMED: 'REDEEMED',
  ASSIGNED: 'ASSIGNED'
};

const SEARCH_TYPE = {
  EXACT: 'EXACT',
  PARTIAL: 'PARTIAL'
};

const SIGN_IN_CARD_USER_STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  ERROR: 'ERROR'
};

const MANAGED_USER_ACCOUNT_STATUS = {
  EXISTS: 'EXISTS',
  NEW: 'NEW'
};

const LICENSE_BEGIN_VALUES = {
  FIRST_USE: 'FIRSTUSE',
  CREATION: 'CREATION'
};
// note that there allegedly exists a historical alternative ID format in rare use somewhere
// so be careful not to block using this regexp, just warn on non-matches
const EAC_ID_REGEX = /^[a-z0-9]{8}(-?[a-z0-9]{4}){3}-?[a-z0-9]{12}$/i;

const PROGRESS = {
  DEFAULT_PASS_MARK_LEVEL: 75
};

const MANAGED_USER_ACCOUNT_AUTHINFO = {
  connection_type: 'Managed-users',
  user_role: 'MANAGED_USER'
};
const SELF_REGISTERED_LEARNER_ACCOUNT_AUTHINFO = {
  connection_type: 'Self-registered-users',
  user_role: 'LEARNER'
};
const PASSWORD_SUFFIX = 'R@nd0m';

const NOT_FOUND = 'Not Found';

const AUTH0 = 'auth0';

const DUMMY_YOUNG_LEARNER = 'dummyyounglearner';
const DUMMY_LEGACY_USER = 'legacyuser';

const DISMISSED_FLAG_STATUS = {
  TRUE: 'TRUE',
  FALSE: 'FALSE'
};

const BLOCK_LIST_STATUS = {
  BLOCKED: 'BLOCKED',
  ACTIVE: 'ACTIVE'
};
const TASK_ASSIGNMENT_ACTIONS = {
  SET: 'SET',
  UPDATE: 'UPDATE',
  CANCEL: 'CANCEL'
};
const TASK_ASSIGNMENT_PROGRESS_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  COMPLETED: 'COMPLETED'
};
const TASK_ASSIGNMENT_STATUS = {
  ACTIVE: 'ACTIVE',
  CANCELLED: 'CANCELLED'
};
const ORG_EXCLUDED_REASON = {
  BLOCKED: 'Membership limit reached: Organization has higher membership records then the allowed limit',
  ACTIVE: 'Success'
};
const POLLING_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error'
};
const ORG_EXCLUDED_REASON_TYPES = {
  TIMEOUT: 'Extraction-Timeout',
  SIZELIMIT: 'Membership limit reached',
  UNEXPECTED: 'Unexpected error',
  ARCHIVED: 'Organization is archived'
};

const EXTRACT_OPTIONS = {
  saveToDatabase: true,
  useEacForOrgUserLookups: true,
  useEacForGroupLookups: true,
  extractEntitlements: true,
  extractUserRoles: false,
  extractUsers: false,
  isOrgAllowed: true
};

const DATA_ENTITIES = {
  ALL: 'all',
  GROUP: 'group',
  MEMBERSHIP: 'membership',
  GROUP_WITH_MEMBERSHIP: 'groupWithMembership',
  USER: 'user',
  USERS_IN_ORG: 'userInOrg',
  USER_ROLES: 'userRoles',
  ORG_SUBSCRIPTION: 'orgSubscription',
  USER_LICENCES: 'userLicences',
  SUBSCRIPTION_PRODUCTS: 'subscriptionProducts',
  PRODUCTS: 'products',
  PRODUCT_DEFINITION: 'productDefinition',
  ASSIGNMENTS: 'assignments',
  ORGANIZATION: 'org',
  CLASS: 'class',
  COHORT: 'cohort',
  USER_ENROLMENT: 'userEnrolment',
  COURSE_ALLOCATION: 'courseAllocation',
  ACTIVATION_CODES: 'activationCodes',
  ORG_CODE_POOL: 'orgCodePool'
};

const REQUEST_OPERATION = {
  LIST: 'LIST',
  READ: 'READ',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE'
};

const DBHOOK_ENTITIES = {
  USER: 'USER',
  PRODUCT: 'PRODUCT',
  PRODUCT_GROUP: 'PRODUCT_GROUP',
  USER_LICENSE: 'USER_LICENSE',
  ACTIVATION_CODE_BATCH: 'ACTIVATION_CODE_BATCH',
  ACTIVATION_CODE: 'ACTIVATION_CODE',
  ASSIGNMENT: 'ASSIGNMENT',
  ORG_CODE_POOL: 'ORG_CODE_POOL'
};

const DBHOOK_ACTIONS = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE'
};

const JOINING_CODE_STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED'
};

const PRODUCT_TARGET_USERTYPE = {
  STUDENT: 'student',
  TEACHER: 'teacher'
};

const ACTIVATION_CODES_VALIDATION = {
  tokenFromDateInvalid: 'Token Valid From cannot be less than current date',
  tokenToDateInvalid: 'Token Valid To cannot be less than Token Valid From date',
  tokenLessThanToday: 'Token Valid To cannot be less than current date',
  licenseDateInvalid: 'License End Date cannot be less than License Start Date',
  batchNameInvalid: 'Batch Name is mandatory'
};

const AUTH0_CUSTOM_MESSAGES = {
  userNotExists: 'The user does not exist.',
  userAlreadyExists: 'The user already exists.',
  tokenPrefix: 'Bearer '
};

const ACTIONING_ENTITY = {
  INTERNAL_SYSTEM: 'InternalSystem'
};

const RESOURCE_TYPE = {
  COURSE_RESOURCE: 'COURSE_RESOURCE',
  ASSESSMENT: 'ASSESSMENT'
};

const ERROR_CODES = {
  ROLE_EXCEEDED: 'ROLE_EXCEEDED'
};

const MARKETING_PREFERENCES_USER_TYPES = {
  ADMIN: 'ADMIN',
  PARENT: 'PARENT',
  TEACHER: 'TEACHER',
  STUDENT: 'STUDENT',
  UNKNOWN: 'UNKNOWN'
};

const EXPIRY_EMAIL_MODES = {
  EMAIL_DIGEST: 'EMAIL_DIGEST',
  NO_EMAIL: 'NO_EMAIL'
};

const EMAIL_TEMPLATES = {
  CES_RECALL_ACTIVATION_CODE: 'eps/activationCodeRecalled',
  HUB_RECALL_ACTIVATION_CODE: 'eps/activationCodeRecalledHub',
  SUSPICIOUS_ACTIVITY: 'eps/registerLicenceSuspiciousActivity'
};

/**
 * Glossary Contexts are folder names and used as constants to refer
 * to these glossaries inside application code
 */
const GLOSSARY_CONTEXTS = {
  EPS_CLIENT: 'eps-client', // all EPS client glossaries
  EPS_NOTIFICATIONS: 'eps-notification', // all EPS email/in app notification glossaries
  OXFORD_PREMIUM_EMAIL: 'premium-email', // Oxford Premium branded emails (OUPE)
  OXFORD_PREMIUM_COUPONS_EMAIL: 'premium-coupons-email', // Oxford Premium branded emails (OUPE) specific to coupons
  OXFORD_PREMIUM_COUPONS_CHECKOUT_EMAIL: 'premium-coupons-checkout-email', // Oxford Premium branded emails (OUPE) specific to product purchases
  ORB_BRANDED_EMAIL: 'orb-email', // Oxford Reading Buddy image-heavy branded emails
  ORB_TABULAR_EMAIL: 'orb-light-email', // Oxford Reading Buddy level up notifications email
  NGS_EMAIL: 'ngs-email' // NGS emails (currently unused due to project hibernation)
};

const TERMS_AND_CONDITIONS = {
  DATE_CREATED: 'createdAt',
  IS_OPT_IN: 'isOptIn',
  TYPE: 'type',
  TERMS: 'terms'
};
const USER_TERMS_AND_CONDITIONS = {
  USER_ID: 'userId',
  TYPE: 'type',
  PLATFORM_CODE: 'platformCode',
  ACCEPTED: 'accepted',
  DATE_CREATED: 'dateCreated',
  SOURCE: 'source'
};

const REASONS_HELD_BACK = {
  PARTIAL_USER: 'create-test-centre-user-partial-registered',
  PARTIAL_ORG: 'create-test-centre-org-partial-registered'
};

// eslint-disable-next-line max-len
const DUOC_PROD_DOMAINS_REGEX = /(@duoc.cl|@duocuc.cl|@profesor.duoc.cl|@profesor.duocuc.cl|duocepstesting@outlook.com)$/i;

const DUOC_NON_PROD_DOMAINS_REGEX = /(@duoc.stud.com|@duoc.prof.com|@duoc.admin.com|duocepstesting@outlook.com)$/i;

const LTI_DEPLOYMENT_STATUS = {
  ACTIVE: 'ACTIVE'
};

const LTI_TOOL_STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED'
};

const LTI_LAUNCH_TYPE = {
  HUB_MODE: 'HubMode',
  LMS_HUB_MODE: 'LMSHubMode',
  LMS_MODE: 'LMSMode'
};

const ELT_PRODUCT_TYPES = {
  STUDENTBOOK: 'studentbook',
  WORKBOOK: 'workbook',
  ONLINEPRACTICE: 'onlinepractice'
};

const ELT_PRODUCT_TYPES_SHORT = {
  STUDENTBOOK_SHORT: 'sb',
  WORKBOOK_SHORT: 'wb',
  ONLINEPRACTICE_SHORT: 'op'
};

const INVALID_ABBREVIATION = 'Invalid abbreviation';

const VALID_ISBN_REGEX = /^\d{13}$/;

const USER_METADATA_CONFIG = {
  VST_CONFIG: 'VST_CONFIG',
  HUB_CONFIG: 'HUB_CONFIG',
  EBOOKSAMPLE: 'EBOOKSAMPLE',
  CONTACT_TYPE: 'CONTACT_TYPE',
  GUEST_USER: 'GUEST_USER',
  YOUNG_LEARNER: 'YOUNG_LEARNER'
};

const STATEMENT_FORMAT = {
  OLB: 'OLB',
  ELTCORE: 'ELTCORE'
};

const PDM = {
  RESOURCES_SECTION: 'RESOURCES SECTION',
  CORE_STRUCTURE: 'CORE STRUCTURE'
};

const ELTCORE_ACTIVITY_MAX_ATTEMPTS = 3;

const PLACEMENT_RESULTS_STATUS = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED'
};

const UNDER_12_REGEX = /^$|^(yes)$/i;

module.exports = {
  URL_ENCODED: 'application/x-www-form-urlencoded',
  JSON_ENCODED: 'application/json',
  JSON_ENCODED_WITH_CHARSET: 'application/json;charset=UTF-8',
  JSON: 'application/json', // Deprecated: use JSON_ENCODED to avoid clash with JSON global
  ENTITIES,
  ARIA_ENTITIES,
  CONTENT_ENTITIES,
  CONTENT_SOURCES,
  EPS_EVENT_TYPES,
  EPS_EVENT_ACTIONS,
  LOR_ENTITIES,
  EREADER_ENTITIES,
  EREADER_REPORT_ENTITIES,
  EREADER_ANOTATION_TYPES,
  EREADER_ANNOTATION_STATUS,
  DATA_CATEGORIES,
  CONTENT_STATES,
  EPS_CONTENT_DEFAULT_ASSET_ENV,
  CONTENT_DATABASES,
  READ_MODES,
  WRITE_MODES,
  WRITE_CONCERN,
  BULK_OPERATIONS,
  STATUS_TYPE,
  SCHEMA_LEVELS,
  ORG_STATUS,
  ORG_REGISTRATION_STATUS,
  OUP_EMAIL_REGEX,
  OLB_UID_REGEX,
  TOKEN_TYPE,
  TOKEN_ERRORS_TYPE,
  GROUP_TYPE,
  GROUP_STATUS,
  CLASS_STATUS,
  CLASS_TYPE,
  OPT_MAIN_FEATURES,
  USER_STATUS,
  USER_TYPE,
  USER_REGISTRATION_STATUS,
  USER_ROLES,
  TOKEN_STATUS,
  MEMBERSHIP_STATUS,
  ENTITLEMENT_TYPE,
  ENTITLEMENT_STATUS,
  SEARCH_POLL_INTERVAL: 500,
  SEARCH_POLL_TIMEOUT: 21000,
  API_VERSIONS,
  ACTIVE_STATUS: 'ACTIVE',
  ARCHIVED_STATUS: 'ARCHIVED',
  SEARCH_SIGNIN_CARDS_INTERVAL: 500,
  SEARCH_SIGNIN_CARDS_TIMEOUT: 15000,
  ORG_REGISTRATION_CONTEXT,
  DELIMITERS,
  INVITATION_STATUS,
  PLATFORMS,
  PLATFORM_CODES,
  PLATFORM_SYSTEM_ID,
  COHORTS,
  LINKED_PRODUCTS: 'linkedProducts',
  LICENSE_TIME_PERIOD,
  TIME_UNIT,
  LICENSE_TYPE,
  LICENSE_EXPIRY_DATES,
  LICENSE_BEINGON,
  LICENSE_STATUS,
  SEARCH_TYPE,
  AWS_CLOUD_SEARCH_CLAUSE_LIMIT: 1024,
  SIGN_IN_CARD_USER_STATUS,
  MANAGED_USER_ACCOUNT_STATUS,
  EAC_ID_REGEX,
  ADD_STUDENT: 'addStudent',
  PROGRESS,
  MANAGED_USER_ACCOUNT_AUTHINFO,
  SELF_REGISTERED_LEARNER_ACCOUNT_AUTHINFO,
  AUTH_SYSTEMURL: 'https://edu.oup.com',
  NOT_GIVEN: 'notgiven',
  YOUNGLEARNER_EMAIL: 'dummyyoungleaner@oup.com',
  AUTH_WEBHOOK_TOKEN: 'Auth0 P@$$w0rd',
  AUTH_USER_AGENT: 'auth0-extensions auth webhook',
  REMOVE: 'REMOVE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  INSERT: 'INSERT',
  FIRST_READ: 'FIRST_READ',
  PRESEED: 'PRESEED',
  AUTH0_BETA_REGION: 'EUROPE_BETA',
  PASSWORD_SUFFIX,
  NOT_FOUND,
  AUTH0,
  EMAIL_DATE_FORMAT,
  DUMMY_YOUNG_LEARNER,
  DUMMY_LEGACY_USER,
  DISMISSED_FLAG_STATUS,
  BLOCK_LIST_STATUS,
  ORG_EXCLUDED_REASON,
  ORG_EXCLUDED_REASON_TYPES,
  EXTRACT_OPTIONS,
  DATA_ENTITIES: Object.freeze(DATA_ENTITIES),
  REQUEST_OPERATION,
  JOINING_CODE_STATUS,
  NO_COUNTRY_CODE,
  NO_COUNTRY_CODE_REGEX,
  PRODUCT_TARGET_USERTYPE,
  ACTIVATION_CODES_VALIDATION,
  AUTH0_CUSTOM_MESSAGES,
  ACTIONING_ENTITY,
  RESOURCE_TYPE,
  DATA_SOURCES,
  EAC_ORGCODEPOOL_ERRORCODES,
  ORG_CODE_POOL_STATUS,
  TASK_ASSIGNMENT_ACTIONS,
  TASK_ASSIGNMENT_STATUS,
  TASK_ASSIGNMENT_PROGRESS_STATUS,
  ERROR_CODES,
  EAC_ORGANIZATION_ARCHIVED,
  EAC_ERROR_FETCHING_USERS_OF_GROUP,
  EAC_ERROR_FETCHING_LICENSES,
  EAC_ERROR_FETCHING_MEMBERSHIP,
  DBHOOK_ENTITIES,
  DBHOOK_ACTIONS,
  LICENSE_MAPPING,
  UNITTYPE_MAPPING,
  BEGINON_MAPPING,
  MARKETING_PREFERENCES_USER_TYPES,
  EXPIRY_EMAIL_MODES,
  LICENSE_BEGIN_VALUES,
  EMAIL_TEMPLATES,
  POLLING_STATUS,
  RESPONSE_STATUS,
  GLOSSARY_CONTEXTS,
  TERMS_AND_CONDITIONS,
  DUOC_PROD_DOMAINS_REGEX,
  DUOC_NON_PROD_DOMAINS_REGEX,
  USER_TERMS_AND_CONDITIONS,
  REASONS_HELD_BACK,
  LTI_DEPLOYMENT_STATUS,
  LTI_TOOL_STATUS,
  LTI_LAUNCH_TYPE,
  ELT_PRODUCT_TYPES,
  INVALID_ABBREVIATION,
  VALID_ISBN_REGEX,
  USER_METADATA_CONFIG,
  ELT_PRODUCT_TYPES_SHORT,
  STATEMENT_FORMAT,
  ORG_MAX_CREDITS,
  PDM,
  HTTP_METHOD_TYPE,
  ELTCORE_ACTIVITY_MAX_ATTEMPTS,
  PLACEMENT_RESULTS_STATUS,
  UNDER_12_REGEX
};
